//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfImagePlaceholder',
  props: {
    imageHeight: {
      type: Number,
      default: 100,
    },
    imageWidth: {
      type: Number,
      default: undefined,
    },
  },
  setup(props, { root }) {
    const height = props.imageHeight;
    const width =
      props.imageWidth ||
      Math.round(
        (root.$themeConfig?.productCard?.imageAspectRatio || 1) * height
      );

    return {
      width,
      height,
    };
  },
});
