





























import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import {
  addFullScreenEventListener,
  removeFullScreenEventListener,
  getFullscreenElement,
} from '@vf/shared/src/utils/helpers/fullscreen';

import { Youtube } from 'vue-youtube';

type YoutubeVideoProp = {
  name: string;
  options: {
    autoplay: boolean;
    hideControls: boolean;
    muted: boolean;
    loop: boolean;
    showPlayButton: boolean;
    showSubtitles: boolean;
  };
};

type YoutubeDataProp = {
  id: string;
  videoId: string;
  url: string;
};

/**
 * More about playback controls and settings: https://developers.google.com/youtube/iframe_api_reference#playing-a-video
 */
interface Player {
  playVideo: () => Promise<void>;
  pauseVideo: () => Promise<void>;
  mute: () => Promise<void>;
  unMute: () => Promise<void>;
  isMuted: () => Promise<boolean>;
  getCurrentTime: () => Promise<number>;
  getDuration: () => Promise<number>;
}

/**
 * More about supported parameters: https://developers.google.com/youtube/player_parameters#Parameters
 */
type YtPlayerSettingsBool = 0 | 1;
type YtPlayerSettings = {
  origin?: string;
  autoplay?: YtPlayerSettingsBool;
  controls?: YtPlayerSettingsBool;
  loop?: YtPlayerSettingsBool;
  cc_load_policy?: YtPlayerSettingsBool;
  modestbranding?: YtPlayerSettingsBool;
  playsinline?: YtPlayerSettingsBool;
};

export default defineComponent({
  name: 'VfYoutubeVideo',
  serverCacheKey: getCacheKeyFromProps,
  components: {
    Youtube,
  },
  props: {
    /** Main video data */
    video: {
      type: Object as PropType<YoutubeVideoProp>,
      default: (): YoutubeVideoProp => ({
        name: '',
        options: {
          autoplay: false,
          hideControls: false,
          muted: true,
          loop: false, // Youtube will only play through playlists with this is active to avoid bloating user engagemnt https://developers.google.com/youtube/iframe_api_reference#Playback_controls
          showPlayButton: false, // Actually it's not possible to hide showPlayButton without hiding all controls for youtube video
          showSubtitles: false,
        },
      }),
    },
    /** Youtube video data */
    youtubeData: {
      type: Object as PropType<YoutubeDataProp>,
      default: (): YoutubeDataProp => ({
        id: '',
        videoId: '',
        url: '',
      }),
      required: true,
      validator: (obj: YoutubeDataProp) => {
        return (
          obj.videoId?.length === 11 &&
          obj.id?.length >= 11 &&
          obj.url?.length > 11
        );
      },
    },
    /** Determines if additional div for touch events should be attached. Unfortunately youtube videos use iframe which catch all touch events */
    touchLayer: {
      type: Boolean,
      default: false,
    },
    /** Set a fixed 16:9 Aspect Ratio */
    setAspect16To9: {
      type: Boolean,
      default: false,
    },
    customVideo: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const youtubeRef = ref<{ player?: Player; $el: HTMLMediaElement }>(null);
    const playerSettings: YtPlayerSettings = {
      autoplay: props.video?.options?.autoplay ? 1 : 0,
      controls: !props.video?.options?.hideControls ? 1 : 0,
      cc_load_policy: props.video?.options?.showSubtitles ? 1 : 0,
      loop: props.video?.options?.loop ? 1 : 0,
      modestbranding: 1,
      playsinline: 1,
    };
    const player = computed<Player>(() => youtubeRef.value?.player);
    const isPlaying = ref<boolean>(false);
    let videoDuration = 0;
    let isReady = ref(false);
    let playerWasMuted = false;

    const isEventsTracked = ref<boolean>(playerSettings.autoplay === 0);

    const toggleVideoPlay = async (): Promise<void> => {
      isPlaying.value
        ? await player.value?.pauseVideo()
        : await player.value?.playVideo();
    };

    const onVideoHidden = async (): Promise<void> => {
      if (!playerSettings.autoplay) {
        await player.value?.pauseVideo();
      } else {
        playerWasMuted = await player.value?.isMuted();
        player.value?.mute();
      }
    };

    const onVideoVisible = async (): Promise<void> => {
      if (
        playerSettings.autoplay &&
        !playerWasMuted &&
        !props.video?.options?.muted
      ) {
        await player.value?.unMute();
      }
    };

    const handleReadiness = () => {
      isReady.value = true;
    };

    const onVideoPlay = async () => {
      isPlaying.value = true;
      emit('playing', true);
      if (isReady.value) {
        const videoCurrentTime = Math.round(
          await player.value.getCurrentTime()
        );

        props.customVideo &&
          emit('video-event', {
            eventCategory: 'Customs',
            eventAction: 'Add Your Touch',
            name: 'Start Playing',
            nonInteractive: false,
          });

        isEventsTracked.value &&
          emit('video-event', {
            eventAction: videoCurrentTime === 0 ? 'Start' : 'Play',
            videoDuration,
            videoCurrentTime,
            name: props.video.name,
          });
      }
    };
    const onVideoStop = async () => {
      isPlaying.value = false;
      emit('playing', false);
      const videoCurrentTime = Math.round(await player.value.getCurrentTime());

      (isEventsTracked.value || videoCurrentTime !== videoDuration) &&
        emit('video-event', {
          eventAction:
            videoCurrentTime === videoDuration ? 'Complete' : 'Pause',
          videoDuration,
          videoCurrentTime,
          name: props.video.name,
        });

      isEventsTracked.value = true;
    };

    let myFullScreenOpened = false;

    const onFullScreen = async () => {
      // check if full screen is for my video
      const fullScreenElement = getFullscreenElement();
      if (
        (fullScreenElement && fullScreenElement.id === props.youtubeData.id) ||
        myFullScreenOpened
      ) {
        myFullScreenOpened = !!fullScreenElement;

        const videoCurrentTime = Math.round(
          await player.value.getCurrentTime()
        );
        emit('video-event', {
          eventAction: `Full Screen ${myFullScreenOpened ? 'On' : 'Off'}`,
          videoDuration,
          videoCurrentTime,
          name: props.video.name,
        });
      }
    };

    const vfObserverOptions = ref({
      root: null,
      rootMargin: '-1px',
      threshold: 0.5,
    });

    onMounted(async () => {
      const windowAny = window as any;

      const carousel = youtubeRef.value.$el.closest('.ui-scroll-container');
      vfObserverOptions.value.root = carousel;

      const key = `youTubeVideoListener_${props.youtubeData.id}`;
      if (!windowAny[key]) {
        // solve double call issue, caused by mount twice
        addFullScreenEventListener(onFullScreen);
        windowAny[key] = true;
      }
      videoDuration = await player.value?.getDuration();
      props.video?.options?.muted && (await player.value?.mute());

      // vue-youtube not always autoplaying videos. handle this manually.
      props.video?.options?.autoplay &&
        !isPlaying.value &&
        (await player.value?.playVideo());
    });

    onUnmounted(() => {
      removeFullScreenEventListener(onFullScreen);
    });

    return {
      isPlaying,
      youtubeRef,
      playerSettings,
      toggleVideoPlay,
      onVideoHidden,
      onVideoVisible,
      onVideoPlay,
      onVideoStop,
      onFullScreen,
      handleReadiness,
      isEventsTracked,
      vfObserverOptions,
    };
  },
});
