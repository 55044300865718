//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'VfSubNavigation',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    activeItem: {
      type: [String, Array],
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    separator: {
      type: String,
      default: '/',
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      isCoreRedesignEnabled,
    };
  },
  data: () => ({
    isMobileNavigationOpen: false,
  }),
  computed: {
    activeItemName() {
      return this.items.find((item) => item.url === this.activeItem)?.name;
    },
    navigationsModify() {
      return this.$root.$themeConfig?.subNavigation?.subModify || '';
    },
  },
  methods: {
    closeDropdownOnMobile() {
      this.isMobileNavigationOpen = false;
    },
    isActiveClass(itemName) {
      return itemName === this.activeItemName;
    },
  },
});
