





















import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from '@vue/composition-api';
import {
  addFullScreenEventListener,
  fullScreenStatus as isFullScreen,
  removeFullScreenEventListener,
} from '@vf/shared/src/utils/helpers/fullscreen';

export default defineComponent({
  name: 'VfHero',
  setup() {
    const refHeroSwiper = ref<{ $el: HTMLElement } | null>(null);

    const onSlideChange = (scrollInfo) => {
      if (isFullScreen()) return false;

      const activeVideo = refHeroSwiper.value?.$el.querySelector<HTMLVideoElement>(
        `.vf-hero-item:nth-child(${scrollInfo.activeItem + 1}) video`
      );
      if (activeVideo) activeVideo.muted = true;
    };

    const onFullScreen = () => {
      if (isFullScreen()) return false;

      // Mute all videos when close fullScreen
      refHeroSwiper.value?.$el
        .querySelectorAll<HTMLVideoElement>('.vf-hero-item video')
        .forEach((video) => {
          video.muted = true;
        });
    };

    onMounted(() => {
      addFullScreenEventListener(onFullScreen);
    });

    onBeforeUnmount(() => {
      removeFullScreenEventListener(onFullScreen);
    });

    return { isFullScreen, onFullScreen, onSlideChange, refHeroSwiper };
  },
});
