//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from './Molecule.Modal';
import { focus } from '@vf/shared/src/utils/directives';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'PromoBar',
  components: { Modal },
  directives: { focus },
  serverCacheKey: getCacheKeyFromProps,
  props: {
    message: {
      type: [Object, Array],
      default: () => ({
        text: '',
        actionText: '',
        actionType: '',
        color: '',
        titleClassModifier: '',
        modalContent: '',
        linkTarget: '',
        openInNewTab: false,
      }),
    },
    interval: {
      type: Number,
      default: 3000,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    dataId: {
      type: String,
      default: '',
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    closeLabel: {
      type: String,
      default: '',
    },
    wholeBarClickable: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      isCoreRedesignEnabled,
    };
  },
  data() {
    return {
      messageIndex: 0,
      modalVisible: false,
      modalContent: '',
      modalTitle: '',
      promoVisible: true,
      intervalId: '',
      isLoading: true,
    };
  },
  computed: {
    messages() {
      if (this.$viewport.isSmall) {
        return this.message.flat();
      }

      return this.message;
    },
    currentMessage() {
      return Array.isArray(this.messages)
        ? this.messages[this.messageIndex]
        : this.messages;
    },
    isMultiSlide() {
      return Array.isArray(this.currentMessage);
    },
  },
  mounted() {
    if (Array.isArray(this.messages) && this.interval > 0) {
      this.promobarRotation();
      this.$on('hook:beforeDestroy', () => clearInterval(this.intervalId));
    }

    const hidePromo = sessionStorage.getItem('hidePromo');
    if (hidePromo) {
      const hidePromoArray = hidePromo.split(',');
      this.promoVisible = !hidePromoArray.includes(`promo-${this.dataId}`);
    }

    if (!this.promoVisible) {
      this.$emit('close');
    }

    this.isLoading = false;

    this.$emit('message-updated');
  },
  methods: {
    getPromobarLink({ linkTarget, modalContent }) {
      return linkTarget?.includes('data-id') || modalContent ? '' : linkTarget;
    },
    handlePromoBarAction({ linkTarget, modalContent, text }) {
      if (linkTarget?.includes('data-id')) {
        this.$emit('click:promobar-modal-link', linkTarget);
      } else if (modalContent) {
        this.modalVisible = true;
        this.modalContent = modalContent;
        this.modalTitle = text;
      }
    },
    handleModalClose() {
      this.modalVisible = false;
      this.modalContent = '';
      this.modalTitle = '';
      this.$emit('close-modal');
    },
    handleClosePromo() {
      const hidePromoLS = sessionStorage.getItem('hidePromo');
      const hidePromoArray = [hidePromoLS, `promo-${this.dataId}`];
      sessionStorage.setItem('hidePromo', hidePromoArray);
      this.promoVisible = false;
      this.$emit('close');
    },
    promobarRotation() {
      this.intervalId = setInterval(() => {
        this.messageIndex =
          this.messageIndex < this.messages.length - 1
            ? this.messageIndex + 1
            : 0;
        this.$emit('message-updated');
      }, this.interval);
    },
    pauseRotation() {
      clearInterval(this.intervalId);
    },
  },
});
