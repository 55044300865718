//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { focus } from '@vf/shared/src/utils/directives';
import VfImagePlaceholder from './Atom.ImagePlaceholder.vue';
import { defineComponent, inject } from '@vue/composition-api';
import useRootInstance from '../../theme/shared/useRootInstance';
export default defineComponent({
  name: 'VfImageGrid',
  directives: { focus },
  components: {
    VfImagePlaceholder,
  },
  props: {
    buttonsData: {
      type: Object,
      default: () => ({}),
    },
    /** Flag if pan navigation/arrows should be visible for main images. */
    panNavigationVisible: {
      type: Boolean,
      default: true,
    },
    /** Flag if X button for exiting full screen mode should be visible at the top right corner. */
    closeButtonVisible: {
      type: Boolean,
      default: true,
    },
    /** Gallery swipper direction. */
    gallerySwiperDirection: {
      type: String,
      default: 'vertical',
    },
    /** Flag if fullscreen gallery should be navigated with mouse scroll */
    scrollNavigationEnabled: {
      type: Boolean,
      default: true,
    },
    /** Flag if thumbnails should show for mobile */
    thumbnailsMobileEnabled: {
      type: Boolean,
      default: false,
    },
    /** Flag if dots navigation should show for mobile */
    dotsNavigationEnabled: {
      type: Boolean,
      default: true,
    },
    /** Flag if arrows navigation should show for mobile */
    arrowsNavigationEnabled: {
      type: Boolean,
      default: true,
    },
    /** Thumbnails image width */
    thumbnailWidth: {
      type: Number,
      default: 97,
    },
    /** Thumbnails image height */
    thumbnailHeight: {
      type: Number,
      default: 115,
    },
    /** Space between thumbnails images */
    thumbnailSpaceBetween: {
      type: Number,
      default: 10,
    },
    /** Flag if zoom animation should be disabled when max or min zoom is reached */
    disableZoomAnimation: {
      type: Boolean,
      default: true,
    },
    /** Flag if zoom controls should show/hide upon tap control in mobile */
    showZoomControlsOnTap: {
      type: Boolean,
      default: true,
    },
    images: {
      type: Array,
      default: () => [],
    },
    /** should be current gallery image lazy loaded */
    lazy: {
      type: Boolean,
      default: true,
    },
    /** Optional value for the fetch priority attribute for images */
    imageFetchPriority: {
      type: String,
      default: null,
    },
    iconSize: {
      type: String,
    },
    swiperControlsClass: {
      type: [String, Object],
      default: '',
    },
    imageWidth: {
      type: [Number, String],
      default: 1284,
    },
    imageHeight: {
      type: [Number, String],
      default: 1284,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.imageGrid;

    // To be cleaned up with GLOBAL15-63801
    const isVansPdpRedesignEnabled = inject('isVansPdpRedesignEnabled');

    return { theme, isVansPdpRedesignEnabled };
  },
  data() {
    return {
      fullscreenGalleryOpen: false,
      selectedImageIndex: 0,
      showThumbnails: false,
    };
  },
  computed: {
    imagesInterface() {
      return this.images.map((image, index) => ({
        video: image.video,
        id: this.getImageId(index),
        backgroundUrl: this.getImageUrl(image.src),
        backgroundZoomUrl: this.getImageUrl(image.zoom),
        zoomUrl: image.zoom,
        alt: image.alt,
      }));
    },
    imageGridSize() {
      return this.images.length === 1 && this.theme.useSingleLargeImage
        ? '9'
        : '6';
    },
    centerImage() {
      return this.images.length === 1 && this.theme.useSingleLargeImage
        ? true
        : false;
    },
    aspectRatio() {
      return { 'aspect-ratio': `${this.imageWidth} / ${this.imageHeight}` };
    },
  },
  methods: {
    toggleFullScreen(index) {
      this.fullscreenGalleryOpen = true;
      this.showThumbnails = true;
      this.selectedImageIndex = index;
      this.$emit('open-fullscreen', index);
    },
    exitFullScreen() {
      this.fullscreenGalleryOpen = false;
    },
    zoomImageAction({ zoomScale, index, reset }) {
      this.$emit('zoom-image', {
        zoomScale: zoomScale,
        index: index,
        reset,
      });
    },
    changeImageClickAction(index) {
      this.$emit('change-image-click', index);
    },
    zoomIn: function (val) {
      if (val.target.id) {
        const image = document.querySelector(`#${val.target.id}`);
        const width = image.offsetWidth;
        const height = image.offsetHeight;
        const mouseX = val.offsetX;
        const mouseY = val.offsetY;

        const bgPosX = (mouseX / width) * 100;
        const bgPosY = (mouseY / height) * 100;

        image.style.backgroundPosition = `${bgPosX}% ${bgPosY}%`;
      }
    },
    resetZoom: function (val) {
      const image = document.querySelector(`#${val.target.id}`);
      if (image) {
        image.style.backgroundPosition = 'center';
        image.style.backgroundImage = image.getAttribute('data-image-lr');
      }
    },
    preloadImage(src) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = resolve;
        image.onerror = reject;
        image.src = src;
      });
    },
    setImageZoom: async function (val) {
      const image = document.querySelector(`#${val.target.id}`);
      if (image) {
        try {
          if (!image.getAttribute('data-image-hr-loaded')) {
            await this.preloadImage(image.getAttribute('data-image-hr-src'));
            image.setAttribute('data-image-hr-loaded', 'true');
          }
          image.style.backgroundImage = image.getAttribute('data-image-hr');
        } catch (e) {
          this.$log.error(
            '[Organism.ImageGrid.vue::getImageUrl] Unable to fetch image',
            e
          );
        }
      }
    },
    getImageId(index) {
      return `image-hover-zoom-${index}`;
    },
    getImageUrl(imageURL) {
      return `url('${imageURL}')`;
    },
    openProductQuickShop(product) {
      this.$emit('click:quickshop', product);
    },
    setUpAriaLabel(current) {
      return this.buttonsData.productImageItem?.replace('{{number}}', current);
    },
  },
});
