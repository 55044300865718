var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.videoToDisplay)?_c('div',{staticClass:"vf-video",style:(_vm.hasOverlay
      ? {
          '--video-overlay-selected-opacity': _vm.videoOverlayOpacity,
          '--video-overlay-selected-color': _vm.videoOverlayBackground,
        }
      : undefined)},[_c('VfButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPlayButton),expression:"showPlayButton"}],staticClass:"vf-button--pure vf-video__play-button",attrs:{"aria-label":"Play Button"},nativeOn:{"click":function($event){return _vm.playVideo.apply(null, arguments)}}},[_c('div',{staticClass:"vf-video__play-button-arrow"})]),_vm._v(" "),_c('UiLink',{staticClass:"vf-video-tile__wrapper",attrs:{"tag":"div","to":_vm.imageLink,"target":_vm.linkTargetAttribute}},[(!_vm.youtube)?_c('video',{ref:"mainVideoRef",attrs:{"itemscope":"","itemtype":"https://schema.org/VideoObject","preload":_vm.videoToDisplay.options.preload === 'disabled'
          ? null
          : _vm.videoToDisplay.options.preload,"poster":_vm.videoToDisplay.poster,"controls":!_vm.videoToDisplay.options.hideControls,"crossorigin":"anonymous","aria-label":_vm.videoToDisplay.seo.title,"playsinline":""},domProps:{"muted":_vm.videoToDisplay.options.muted}},[_c('meta',{attrs:{"itemprop":"datePublished","content":_vm.videoToDisplay.seo.date}}),_vm._v(" "),_c('meta',{attrs:{"itemprop":"description","content":_vm.videoToDisplay.seo.description}}),_vm._v(" "),_c('meta',{attrs:{"itemprop":"name","content":_vm.videoToDisplay.seo.title}}),_vm._v(" "),_c('meta',{attrs:{"itemprop":"uploadDate","content":_vm.videoToDisplay.seo.date}}),_vm._v(" "),_c('meta',{attrs:{"itemprop":"thumbnailUrl","content":_vm.videoToDisplay.poster}}),_vm._v(" "),_c('meta',{attrs:{"itemprop":"contentUrl","content":_vm.videoToDisplay.sources.length > 0
            ? _vm.videoToDisplay.sources[0].src
            : ''}}),_vm._v(" "),(_vm.lazyLoad)?_c('client-only',_vm._l((_vm.videoToDisplay.sources),function(source,key){return _c('source',{key:'source-' + key,attrs:{"src":source.src + '#t=0.001',"type":source.type}})}),0):_vm._l((_vm.videoToDisplay.sources),function(source,key){return _c('source',{key:'source-' + key,attrs:{"src":source.src + '#t=0.001',"type":source.type}})}),_vm._v(" "),_vm._l((_vm.videoToDisplay.options.showSubtitles
          ? _vm.videoToDisplay.tracks
          : []),function(track,key){return _c('track',{key:'track-' + key,attrs:{"src":track.data.uri,"kind":track.kind,"srclang":track.srclang,"label":track.label,"default":track.default}})})],2):_vm._e(),_vm._v(" "),(_vm.youtube)?_c('VfYoutubeVideo',{attrs:{"video":_vm.videoToDisplay,"youtube-data":_vm.youtube,"touch-layer":_vm.useYtTouchLayer,"set-aspect16-to9":_vm.ytAspect16To9,"custom-video":_vm.customVideo},on:{"playing":function($event){return _vm.handleYTVideoPlayStatus($event)},"video-event":function($event){return _vm.$emit('video-event', $event)}}}):_vm._e()],1),_vm._v(" "),_c('UiLink',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOverlayVisible),expression:"isOverlayVisible"}],class:_vm.hasOverlay
        ? [
            'vf-video__overlay',
            ("content-align-" + (_vm.overlayPosition.small) + "-sm"),
            ("content-align-" + (_vm.overlayPosition.medium) + "-md"),
            ("content-align-" + (_vm.overlayPosition.large) + "-lg"),
            {
              'vf-video--transparent-overlay-sm': _vm.transparentOverlay.small,
              'vf-video--transparent-overlay-md': _vm.transparentOverlay.medium,
              'vf-video--transparent-overlay-lg': _vm.transparentOverlay.large,
              'vf-video--visible-overlay-sm':
                _vm.alwaysShowOverlay.small || _vm.showButtonsBelowImage,
              'vf-video--visible-overlay-md':
                _vm.alwaysShowOverlay.medium || _vm.showButtonsBelowImage,
              'vf-video--visible-overlay-lg':
                _vm.alwaysShowOverlay.large || _vm.showButtonsBelowImage,
            } ]
        : undefined,attrs:{"to":_vm.imageLink,"tag":"div","target":_vm.linkTargetAttribute}},[_vm._t("default")],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }