















































































































































































































































































































import { PropType } from 'vue';
import { defineComponent, inject } from '@vue/composition-api';
import VfImagePlaceholder from './Atom.ImagePlaceholder.vue';
import VfThumbnail from './Atom.Thumbnail.vue';
import {
  focus,
  focusTrap,
  lazyImage,
  swipe,
} from '@vf/shared/src/utils/directives';
import {
  addFullScreenEventListener,
  exitFullscreen as exitFullscreenAPI,
  fullScreenStatus,
  removeFullScreenEventListener,
  requestFullscreen as requestFullscreenAPI,
  supportFullScreen,
} from '@vf/shared/src/utils/helpers/fullscreen';

interface BadgeImgMap {
  large: string;
  medium: string;
  small: string;
  alt: string;
  title: string;
}

interface Badge {
  src?: BadgeImgMap;
  alt: string;
  width: string | number;
  height: string | number;
}

export default defineComponent({
  name: 'VfGallery',
  components: { VfImagePlaceholder, VfThumbnail },
  directives: { focus, lazyImage, swipe },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    buttonsData: {
      type: Object,
      default: () => ({}),
    },
    imageWidth: {
      type: [Number, String],
      default: 1284,
    },
    imageHeight: {
      type: [Number, String],
      default: 1284,
    },
    selectedIndex: {
      type: Number,
      default: 0,
    },
    showBadges: {
      type: Boolean,
      default: true,
    },
    badge: {
      type: Object as PropType<Badge>,
      default: () => ({}),
    },
    /** Maximum scale limit for zooming images */
    maxScale: {
      type: Number,
      default: 3.5,
    },
    fullscreenGallery: {
      type: Boolean,
      default: false,
    },
    showThumbnails: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    /** Flag if pan navigation/arrows should be visible for main images. */
    panNavigationVisible: {
      type: Boolean,
      default: false,
    },
    /** Flag if X (close) button for exiting full screen mode should be visible at the top right corner. */
    closeButtonVisible: {
      type: Boolean,
      default: false,
    },
    /** Gallery swipper direction. */
    gallerySwiperDirection: {
      type: String,
      default: 'vertical',
    },
    /** Flag if fullscreen gallery should be navigated with mouse scroll */
    scrollNavigationEnabled: Boolean,
    /** Flag if thumbnails should show for mobile */
    thumbnailsMobileEnabled: {
      type: Boolean,
      default: false,
    },
    /** Flag if dots navigation should show for mobile */
    dotsNavigationEnabled: {
      type: Boolean,
      default: true,
    },
    /** Flag if dots navigation should show for mobile */
    arrowsNavigationEnabled: {
      type: Boolean,
      default: true,
    },
    /** Thumbnails image width */
    thumbnailWidth: {
      type: [Number, String],
      default: 50,
    },
    /** Thumbnails image height */
    thumbnailHeight: {
      type: [Number, String],
      default: 50,
    },
    /** Space between thumbnails images */
    thumbnailSpaceBetween: {
      type: Number,
      default: 13,
    },
    /** Flag if zoom animation should be disabled when max or min zoom is reached */
    disableZoomAnimation: {
      type: Boolean,
      default: false,
    },
    /** Flag if zoom controls should show/hide upon tap control in mobile. When false, it will always visible. */
    showZoomControlsOnTap: {
      type: Boolean,
      default: true,
    },
    /** Enable additional css class for quickShop gallery */
    enableQuickShopClass: {
      type: Boolean,
      default: false,
    },
    /** Optional value for the fetch priority attribute for images */
    imageFetchPriority: {
      type: String,
      default: null,
    },
    swiperControlsClass: {
      type: [String, Object],
      default: '',
    },
    /** Value which allow us to change size of arrows in thumbnails carousel */
    chevronSize: {
      type: String,
      default: '24px',
    },
    hideControls: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // TODO: Cleanup in GLOBAL15-63799
    const isVansPdpRedesignEnabled = inject('isVansPdpRedesignEnabled');
    return {
      activeIndex: 0,
      zoomed: false,
      fullScreen: false,
      fullScreenMobile: false,
      mainImageElement: null,
      zoomVisibilitySettings: {
        controls: !isVansPdpRedesignEnabled.value,
        maximize: !isVansPdpRedesignEnabled.value,
      },
      showArrowsAfterSwipe: false,
      hideArrowsTimeout: null,
      swiperMode: 'normal',
      swiper: null,
    };
  },
  computed: {
    thumbnails() {
      return this.images.map((image, i) => ({
        ...image,
        computedThumbnail:
          typeof image.video === 'undefined'
            ? { src: image.thumbnail, value: `${image.alt} ${i + 1}` }
            : { value: `${image.alt} ${i + 1}` },
      }));
    },
    videoConfig() {
      return { small: this.images[this.activeIndex].video };
    },
    classes() {
      return [
        'gallery',
        {
          gallery__fullscreen: this.fullScreen,
          gallery__qs_thumbnails_left:
            this.enableQuickShopClass &&
            !this.fullScreen &&
            !this.$viewport.isSmall,
        },
      ];
    },
    isThumbnailGalleryVisible() {
      return this.$viewport?.isSmall ? this.thumbnailsMobileEnabled : true;
    },
    arrowClasses() {
      return [
        'gallery__arrows-navigation',
        {
          'gallery__arrows-navigation--isFullscreen': this.fullScreen,
        },
      ];
    },
    plusIconClasses() {
      return {
        disabled:
          this.$refs &&
          this.$refs.zoomer &&
          this.$refs.zoomer.scale === this.$refs.zoomer.maxScale,
      };
    },
    minusIconClasses() {
      return {
        disabled:
          this.$refs &&
          this.$refs.zoomer &&
          this.$refs.zoomer.scale === this.$refs.zoomer.minScale,
      };
    },
    isVideo() {
      return (
        this.images[this.activeIndex] &&
        typeof this.images[this.activeIndex].video !== 'undefined'
      );
    },
    heroImgSrc() {
      return this.zoomed || this.fullScreen
        ? this.images[this.activeIndex].zoom
        : this.images[this.activeIndex].src;
    },
    isSmallViewport() {
      return this.$viewport?.isSmall;
    },
    swiperDirection(): 'vertical' | undefined {
      if (this.isSmallViewport) {
        return undefined;
      }
      return this.gallerySwiperDirection === 'vertical'
        ? 'vertical'
        : undefined;
    },
    displayPanNavigation() {
      return this.images.length > 1 && this.panNavigationVisible;
    },
    showPanNavigationButtons() {
      return this.panNavigationVisible || this.showArrowsAfterSwipe;
    },
    galleryWrapperAspectRatio() {
      return `${this.imageWidth} / ${this.imageHeight}`;
    },
    galleryWrapperFallbackPadding() {
      return (this.imageHeight / this.imageWidth) * 100 + '%';
    },
    arrowNavigationText() {
      return `${this.activeIndex + 1}
        ${this.buttonsData.arrowsNavigationCounterConnector}
        ${this.images.length}`;
    },
  },
  watch: {
    fullScreen(value) {
      if (supportFullScreen() && value !== fullScreenStatus()) {
        value ? this.enterFullScreen() : this.exitFullScreen();
      }
    },
    fullscreenGallery(value) {
      this.fullScreen = value;
    },
    selectedIndex(value) {
      this.activeIndex = this.images[value] ? value : 0;
    },
    images() {
      this.activeIndex = this.images[this.activeIndex] ? this.activeIndex : 0;
      this.zoomed = false;
      this.resetZoom();
    },
  },
  mounted() {
    const isFullscreenGallery = this.$props.fullscreenGallery;
    const indexToSelect = this.$props.selectedIndex - 1;

    if (this.images[indexToSelect]) {
      this.activeIndex = indexToSelect;
      this.changeImage(indexToSelect);
    } else {
      this.activeIndex = 0;
      this.changeImage(0);
    }

    if (isFullscreenGallery) {
      this.fullScreen = true;
    }

    this.setZoomControlsVisibility();
  },
  methods: {
    onWheel(e) {
      if (this.fullScreen && this.scrollNavigationEnabled) {
        this.debounceScroll(this.handleImageNavigation, 300, e);
      }
    },
    onSwipe({ direction }) {
      this.showArrowsAfterSwipe = true;

      if (direction === 'left') {
        this.changeToPrevImage();
      } else {
        this.changeToNextImage();
      }

      clearTimeout(this.hideArrowsTimeout);

      this.hideArrowsTimeout = setTimeout(() => {
        this.showArrowsAfterSwipe = false;
      }, 3000);
    },
    debounceScroll(scrollCb, delay, e) {
      clearTimeout(scrollCb._tId);
      scrollCb._tId = setTimeout(function () {
        scrollCb(e);
      }, delay);
    },
    handleImageNavigation(e) {
      const isScrollUp = e.deltaY < 0;
      const isScrollDown = e.deltaY > 0;
      if (isScrollUp) {
        this.changeToPrevImage();
      } else if (isScrollDown) {
        this.changeToNextImage();
      }
    },
    enterFullScreen() {
      if (this.enableQuickShopClass) {
        this.swiperMode = 'fullScreen';
      }
      addFullScreenEventListener(this.fullScreenCallback);
      requestFullscreenAPI(this.$el);
    },
    exitFullScreen() {
      if (this.enableQuickShopClass) {
        this.swiperMode = 'normal';
      }
      if (supportFullScreen() && fullScreenStatus()) {
        exitFullscreenAPI();
      }
    },
    fullScreenCallback() {
      this.fullScreen = fullScreenStatus();
      if (this.fullScreen) {
        this.scrollToIndex(this.activeIndex);
        focusTrap.bind(this.$el);
        return;
      }
      this.$emit('exit-fullscreen');
      removeFullScreenEventListener(this.fullScreenCallback);
      focusTrap.unbind(this.$el);
    },
    changeImageClick(index) {
      this.$emit('change-image-click', index);
      this.changeImage(index);
    },
    async initSwiper(swiperInstance) {
      if (swiperInstance?.scroller) {
        this.swiper = swiperInstance;
      }
    },
    scrollToIndex(index) {
      if (this.swiper) {
        this.swiper.scroller.activeItem = index;
        this.swiper.scroller.scrollToIndex(index);
      }
    },
    changeImage(index, scroll = false) {
      if (!this.isVideo) {
        this.resetZoom();
      }
      this.activeIndex = index;
      if (scroll) {
        this.scrollToIndex(index);
      }
    },
    changeToPrevImage() {
      if (!this.activeIndex) {
        this.changeImage(this.images.length - 1, true);
      } else {
        this.changeImage(this.activeIndex - 1, true);
      }
      this.$emit('change-image-click', this.activeIndex);
    },
    changeToNextImage() {
      if (this.activeIndex >= this.images.length - 1) {
        this.changeImage(0, true);
      } else {
        this.changeImage(this.activeIndex + 1, true);
      }
      this.$emit('change-image-click', this.activeIndex);
    },
    switchFullScreen() {
      this.$emit('exit-fullscreen');
      !this.fullScreen && this.$emit('open-fullscreen', this.activeIndex);
      this.resetZoom();
      this.fullScreen = !this.fullScreen;
    },
    switchFullScreenMobile() {
      this.resetZoom();
      this.fullScreenMobile = !this.fullScreenMobile;
    },
    resetZoom(emitGtmEvent = false) {
      if (this.$refs.zoomer) {
        this.$refs.zoomer.reset();
        if (emitGtmEvent) {
          this.$emit('zoom-image', {
            zoomScale: Math.round(this.$refs.zoomer.scale * 100),
            index: this.activeIndex,
            reset: true,
          });
        }
      }
    },
    zoomOut(index) {
      if (
        this.disableZoomAnimation &&
        this.$refs.zoomer.scale === this.$refs.zoomer.minScale
      ) {
        this.triggerZoomImage(index);
        return;
      }
      if (this.$refs.zoomer) {
        this.$refs.zoomer.zoomOut();
        this.triggerZoomImage(index);
      }
    },
    zoomIn(index) {
      if (
        this.disableZoomAnimation &&
        this.$refs.zoomer.scale === this.$refs.zoomer.maxScale
      ) {
        this.triggerZoomImage(index);
        return;
      }
      if (this.$refs.zoomer) {
        this.$refs.zoomer.zoomIn();
        this.triggerZoomImage(index);
      }
    },
    triggerZoomImage(index) {
      this.$emit('zoom-image', {
        zoomScale: Math.round(this.$refs.zoomer.scale * 100),
        index: index,
      });
    },
    setZoomControlsVisibility() {
      if (this.showZoomControlsOnTap && this.isSmallViewport) {
        const zoomVisibilitySettingsList = ['controls', 'maximize'];
        zoomVisibilitySettingsList.forEach((setting: string) => {
          if (!this.$themeConfig?.gallery?.zoomVisibilitySettings[setting]) {
            setTimeout(() => {
              this.zoomVisibilitySettings[setting] = false;
            }, 3000);
          }
        });
      } else {
        this.zoomVisibilitySettings['controls'] = true;
        this.zoomVisibilitySettings['maximize'] = true;
      }
    },
  },
});
