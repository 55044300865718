//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { computed } from '@vue/composition-api';

export default {
  name: 'VfControlledVideo',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Hero full slide with source */
    hero: {
      type: Object,
      default: () => ({}),
    },
    /** Hero slide Height */
    slideHeight: {
      type: Number,
      default: 0,
    },
    /** Hero slide index */
    slideIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const customTitleStyles = computed(() => {
      return {
        titleFontSize: props.hero.titleFontSize,
        titleFontFamily: props.hero.titleFontFamily,
        titleFontWeight: props.hero.titleFontWeight,
        titleColor: props.hero.titleColor,
        titleBackgroundColor: props.hero.titleBackgroundColor,
      };
    });
    return {
      customTitleStyles,
    };
  },
};
