import { Element, Doc } from '../directives/types';

export const supportFullScreen = (): boolean => {
  const doc: Doc = document as Doc;
  return (
    doc.fullscreenEnabled ||
    doc.mozFullScreenEnabled ||
    doc.webkitFullscreenEnabled ||
    doc.msFullscreenEnabled
  );
};

export const getFullscreenElement = (): null | Element => {
  const doc: Doc = document as Doc;
  return (
    doc.fullscreenElement ||
    doc.mozFullScreenElement ||
    doc.webkitFullscreenElement ||
    doc.msFullscreenElement
  );
};

export const fullScreenStatus = (): boolean => {
  return getFullscreenElement() ? true : false;
};

export const requestFullscreen = (el: Element): void => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (el.requestFullscreen) {
    el.requestFullscreen();
  } else if (el.msRequestFullscreen) {
    el.msRequestFullscreen();
  } else if (el.mozRequestFullScreen) {
    el.parentElement.mozRequestFullScreen();
  } else if (userAgent.indexOf('edge') != -1) {
    el.parentElement.webkitRequestFullscreen();
  } else if (el.webkitRequestFullscreen) {
    el.webkitRequestFullscreen();
  } else {
    console.log('Fullscreen API is not supported.');
  }
};

export const exitFullscreen = (): void => {
  const doc: Doc = document as Doc;
  if (doc.exitFullscreen) {
    doc.exitFullscreen();
  } else if (doc.msExitFullscreen) {
    doc.msExitFullscreen();
  } else if (doc.mozCancelFullScreen) {
    doc.mozCancelFullScreen();
  } else if (doc.webkitExitFullscreen) {
    doc.webkitExitFullscreen();
  } else {
    console.log('Fullscreen API is not supported.');
  }
};

export const addFullScreenEventListener = (
  callback: (e: Event) => void,
  element?: HTMLElement
): void => {
  const doc: Doc = document as Doc;
  const listener = element || doc;
  if (doc.webkitFullscreenEnabled) {
    listener.addEventListener('webkitfullscreenchange', callback); //Safari
    listener.addEventListener('fullscreenChange', callback); // Edge
  }
  if (doc.fullscreenEnabled) {
    listener.addEventListener('fullscreenchange', callback);
  } else if (doc.mozFullScreenEnabled) {
    listener.addEventListener('mozfullscreenchange', callback);
  } else if (doc.msFullscreenEnabled) {
    listener.addEventListener('MSFullscreenChange', callback);
  }
};

export const removeFullScreenEventListener = (
  callback: (e: Event) => void,
  element?: HTMLElement
): void => {
  const doc: Doc = document as Doc;
  const listener = element || doc;
  if (doc.webkitFullscreenEnabled) {
    listener.removeEventListener('webkitfullscreenchange', callback); //Safari
    listener.removeEventListener('fullscreenChange', callback); // Edge
  }
  if (doc.fullscreenEnabled) {
    listener.removeEventListener('fullscreenchange', callback);
  } else if (doc.mozFullScreenEnabled) {
    listener.removeEventListener('mozfullscreenchange', callback);
  } else if (doc.msFullscreenEnabled) {
    listener.removeEventListener('MSFullscreenChange', callback);
  }
};
