






































































































import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import type {
  CategoryTeasersStyles,
  Icons,
  RegularButtonSize,
  RegularButtonStyle,
  VideoObject,
} from '@vf/api-contract';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { generateDimensionsSettings } from '@vf/shared/src/utils/helpers/generateDimensionsSettings';
import VfImageTileActionButtons from './Molecule.ImageTileActionButtons.vue';

export default defineComponent({
  name: 'VfVideoTile',
  serverCacheKey: getCacheKeyFromProps,
  components: {
    VfImageTileActionButtons,
  },
  props: {
    video: {
      type: Object as PropType<{
        small: VideoObject;
        medium: VideoObject;
        large: VideoObject;
      }>,
      default: () => ({
        small: {
          sources: [],
          seo: {
            title: '',
            date: '',
            description: '',
          },
          tracks: [],
          poster: '',
          options: {
            preload: 'metadata',
            autoplay: false,
            hideControls: false,
            muted: true,
            loop: false,
            showPlayButton: false,
            showSubtitles: false,
          },
        },
      }),
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    text: {
      type: String as PropType<string>,
      default: '',
    },
    useRichText: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    subtitle: {
      type: String as PropType<string>,
      default: '',
    },
    link: {
      type: String as PropType<string>,
      default: null,
    },
    imageLink: {
      type: String as PropType<string>,
      default: null,
    },
    buttonText: {
      type: String as PropType<string>,
      default: '',
    },
    buttonStyle: {
      type: String as PropType<RegularButtonStyle>,
      default: 'tertiary',
    },
    buttonSize: {
      type: String as PropType<RegularButtonSize>,
      default: 'medium',
    },
    buttonIcon: {
      type: String as PropType<Icons>,
      default: '',
    },
    styles: {
      type: Object as PropType<CategoryTeasersStyles>,
      default: () => ({
        titleColor: '',
        titleBackgroundColor: '',
        titleFontSize: '',
        titleFontWeight: '',
        titleFontFamily: '',
        headingLevel: '',
        titleClassModifiers: '',
        textColor: '',
        textBackgroundColor: '',
        textClassModifiers: '',
        subtitleBackgroundColor: '',
        subtitleClassModifiers: '',
        subtitleColor: '',
        subtitleFontSize: '',
        subtitleFontWeight: '',
        subtitleFontFamily: '',
      }),
    },
    contentPosition: {
      type: Object,
      default: () => ({
        small: 'left',
        medium: 'center',
        large: 'center',
      }),
    },
    textAlign: {
      type: Object,
      default: () => ({
        small: 'center',
        medium: 'center',
        large: 'center',
      }),
    },
    dataNavigation: {
      type: String as PropType<string>,
      default: null,
    },
    dataDomLocation: {
      type: String as PropType<string>,
      default: null,
    },
    showOverlay: {
      type: Object,
      default: () => ({
        small: true,
        medium: true,
        large: true,
      }),
    },
    transparentOverlay: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    alwaysShowOverlay: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    showButtonsBelowImage: {
      type: Boolean,
      default: false,
    },
    overlaySettingsSmall: {
      type: Object,
      default: () => ({
        hOffset: null,
        vOffset: null,
        width: '',
      }),
    },
    overlaySettingsMedium: {
      type: Object,
      default: () => ({
        hOffset: null,
        vOffset: null,
        width: '',
      }),
    },
    overlaySettingsLarge: {
      type: Object,
      default: () => ({
        hOffset: null,
        vOffset: null,
        width: '',
      }),
    },
    customVideo: {
      type: Boolean,
    },
    wrapperTag: {
      type: String as PropType<string>,
      default: 'div',
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    openInNewModal: {
      type: Boolean,
      default: false,
    },
    ctaButtons: {
      type: Array,
      default: () => [
        {
          buttonText: '',
          buttonStyle: '',
          buttonSize: '',
          buttonIcon: '',
          buttonIconPosition: '',
          buttonLink: '',
          buttonTargetComponent: '',
        },
      ],
    },
    buttonTargetComponent: {
      type: String,
      default: '',
    },
    lazyLoad: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const customTitleStyles = computed(() => ({
      titleColor: props.styles.titleColor,
      titleFontSize: props.styles.titleFontSize,
      titleFontFamily: props.styles.titleFontFamily,
      titleFontWeight: props.styles.titleFontWeight,
      titleBackgroundColor: props.styles.titleBackgroundColor,
      subtitleColor: props.styles.subtitleColor,
      subtitleFontSize: props.styles.subtitleFontSize,
      subtitleFontFamily: props.styles.subtitleFontFamily,
      subtitleFontWeight: props.styles.subtitleFontWeight,
      subtitleClassModifiers: props.styles.subtitleClassModifiers,
      subtitleBackgroundColor: props.styles.subtitleBackgroundColor,
    }));
    const contentPositionSettingsStyles = computed(() => ({
      '--vf-video-tile-content-position-left':
        props.overlaySettingsSmall.hOffset &&
        `${props.overlaySettingsSmall.hOffset}px`,
      '--vf-video-tile-content-position-left-medium':
        props.overlaySettingsMedium.hOffset &&
        `${props.overlaySettingsMedium.hOffset}px`,
      '--vf-video-tile-content-position-left-large':
        props.overlaySettingsLarge.hOffset &&
        `${props.overlaySettingsLarge.hOffset}px`,
      '--vf-video-tile-content-position-top':
        props.overlaySettingsSmall.vOffset &&
        `${props.overlaySettingsSmall.vOffset}px`,
      '--vf-video-tile-content-position-top-medium':
        props.overlaySettingsMedium.vOffset &&
        `${props.overlaySettingsMedium.vOffset}px`,
      '--vf-video-tile-content-position-top-large':
        props.overlaySettingsLarge.vOffset &&
        `${props.overlaySettingsLarge.vOffset}px`,
    }));
    const contentWidthStyles = computed(() => ({
      '--vf-video-tile-content-width':
        props.overlaySettingsSmall.width || undefined,
      '--vf-video-tile-content-width-medium':
        props.overlaySettingsMedium.width || undefined,
      '--vf-video-tile-content-width-large':
        props.overlaySettingsLarge.width || undefined,
    }));

    const articleLink = computed(() => {
      const link = props.imageLink || props.link;
      return props.wrapperTag === 'a' && link ? link : undefined;
    });

    const overlayColorSettings = computed(() => {
      return generateDimensionsSettings((dimension) => {
        return props.transparentOverlay[dimension.toLowerCase()]
          ? 'transparent'
          : props[`overlaySettings${dimension}`].color;
      });
    });

    const overlayOpacitySettings = computed(() =>
      generateDimensionsSettings((dimension) => {
        return props[`overlaySettings${dimension}`].opacity ?? undefined;
      })
    );

    return {
      customTitleStyles,
      contentPositionSettingsStyles,
      contentWidthStyles,
      articleLink,
      overlayColorSettings,
      overlayOpacitySettings,
    };
  },
});
