var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-hero__controlled-video",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'])}},[_c('VfVideo',{style:({ height: (_vm.slideHeight + "px") }),attrs:{"video":_vm.hero.video,"heading":_vm.hero.headingText,"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'videos'),"use-yt-touch-layer":""},on:{"video-event":function($event){return _vm.$emit('video-event', $event)}}}),_vm._v(" "),_c('div',{staticClass:"vf-hero__container"},[(_vm.hero.headingText)?_c('VfHeading',{class:[
        _vm.hero.titleClassModifier,
        { 'vf-hero__heading--with-background': _vm.hero.whiteBackground },
        'vf-hero__heading' ],attrs:{"data-id":_vm.$attrs['data-id'],"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'teaserTitle'),"title":_vm.hero.headingText,"styles":_vm.customTitleStyles,"modifier":"title-1","level":_vm.hero.headingLevel || 1,"aria-label":!_vm.slideIndex ? _vm.hero.headingText : ''}}):_vm._e(),_vm._v(" "),(_vm.hero.subheadingText)?_c('div',{staticClass:"vf-hero__subheading"},[_c('VfText',{staticClass:"vf-hero__subheading vf-hero__subheading-text",class:[
          _vm.hero.subtitleClassModifier,
          { 'vf-hero__subheading--raw-html': _vm.hero.useRawHtml },
          { 'vf-hero__subheading--with-background': _vm.hero.whiteBackground } ],style:({
          '--vf-hero-subheading-color': _vm.hero.subtitleColor,
        }),attrs:{"font-family":_vm.hero.subtitleFontFamily,"background-color":_vm.hero.subtitleBackgroundColor,"styles":{
          fontSize: _vm.hero.subtitleFontSize,
          fontWeight: _vm.hero.subtitleFontWeight,
        },"data-cm-metadata":_vm.$previewMetaDataLocation(
            _vm.$attrs['data-id'],
            _vm.hero.useRawHtml ? 'teaserRichTextObject' : 'teaserText'
          ),"html":_vm.hero.useRawHtml,"content":_vm.hero.subheadingText}})],1):_vm._e(),_vm._v(" "),(_vm.hero.buttonVisible)?_c('VfButton',{staticClass:"vf-button vf-button--secondary vf-button--small",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'teaserTargets')}},[_vm._v("\n      "+_vm._s(_vm.hero.buttonText)+"\n    ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }