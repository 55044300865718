var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'vf-image-tile__action-buttons',
    ("content-align-middle_" + (_vm.contentPosition.small) + "-sm"),
    ("content-align-top_" + (_vm.contentPosition.medium) + "-md"),
    ("content-align-top_" + (_vm.contentPosition.large) + "-lg"),
    { hidden: _vm.isHidden } ]},[_c('VfButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.link),expression:"link"}],staticClass:"vf-image-tile__button",class:[
      _vm.buttonStyle ? ("vf-button--" + _vm.buttonStyle) : '',
      _vm.buttonSize ? ("vf-button--" + _vm.buttonSize) : '',
      {
        'vf-image-tile__button--hidden-sm': !_vm.showOverlay.small,
        'vf-image-tile__button--hidden-md': !_vm.showOverlay.medium,
        'vf-image-tile__button--hidden-lg': !_vm.showOverlay.large,
      } ],attrs:{"link":_vm.link,"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'teaserTargets'),"icon":_vm.buttonIcon === 'no-icon' ? '' : _vm.buttonIcon,"data-navigation":_vm.dataNavigation,"data-dom-location":_vm.dataDomLocation,"open-in-new-tab":_vm.openInNewTab,"open-in-new-modal":_vm.openInNewModal},on:{"click":function($event){return _vm.emitCustomModal(_vm.buttonTargetComponent)}}},[_vm._v("\n    "+_vm._s(_vm.buttonText)+"\n  ")]),_vm._v(" "),_vm._l((_vm.ctaButtons),function(button,index){return _c('VfButton',{key:index,staticClass:"vf-image-tile__button",class:[
      button.buttonStyle ? ("vf-button--" + (button.buttonStyle)) : '',
      button.buttonSize ? ("vf-button--" + (button.buttonSize)) : '',
      {
        'vf-image-tile__button--hidden-sm': !_vm.showOverlay.small,
        'vf-image-tile__button--hidden-md': !_vm.showOverlay.medium,
        'vf-image-tile__button--hidden-lg': !_vm.showOverlay.large,
      } ],attrs:{"data-navigation":_vm.dataNavigation,"data-dom-location":_vm.dataDomLocation,"open-in-new-tab":_vm.openInNewTab,"open-in-new-modal":_vm.openInNewModal,"icon":button.buttonIcon === 'no-icon' ? '' : button.buttonIcon,"link":button.buttonLink,"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'teaserTargets')},on:{"click":function($event){return _vm.emitCustomModal(button.buttonTargetComponent)}}},[_vm._v("\n    "+_vm._s(button.buttonText)+"\n  ")])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }