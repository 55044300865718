var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-utility-nav",class:{ 'vf-utility-nav--redesign-core': _vm.isCoreRedesignEnabled }},[_c('VfList',{staticClass:"vf-utility-nav__list"},[_vm._l((_vm.navItems),function(item,key){return _c('VfListItem',{key:key,class:[{ 'large-only': !item.mobile }, _vm.listItemAdditionalClass],attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(item.id)}},[_c(_vm.isSSR(item) ? 'div' : 'client-only',{tag:"component"},[_c(_vm.getComponentName(item).type,{tag:"component",class:[
            'vf-utility-nav__link',
            {
              'vf-utility-nav__link-icon':
                item.icon && item.icon !== 'noIcon',
            },
            item.titleClassModifier,
            item.cssClass ],style:({
            '--vf-utility-nav-link-color': item.titleColor,
            '--vf-utility-nav-icon-color': item.titleColor,
            'font-size': item.titleFontSize,
            'font-family': item.titleFontFamily,
            'font-weight': item.titleFontWieght,
          }),attrs:{"aria-label":item.ariaLabel,"role":_vm.getComponentName(item).role,"data-cm-metadata":_vm.$previewMetaDataLocation(item.id, 'teaserTargets'),"to":_vm.getComponentName(item).link,"data-navigation":item.dataNavigation,"data-dom-location":_vm.AnalyticsDataDomLocation.UtilityNavigation},on:{"click":function($event){return _vm.handleNavLinkClick($event, {
              link: item.link,
              title: item.name,
              role: item.role,
              isModal: item.openInNewModal,
            })}}},[(item.icon !== 'noIcon')?_c('VfIcon',{staticClass:"vf-utility-nav__icon",attrs:{"icon":item.icon,"width":_vm.utilityIconSize,"height":_vm.utilityIconSize}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"vf-utility-nav__text",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(item.id, 'teaserTitle')}},[_vm._v("\n            "+_vm._s(item.title)+"\n            "),(item.counter !== null && item.counter >= 0)?_c('span',[_vm._v("\n              "+_vm._s('(' + item.counter + ')')+"\n            ")]):_vm._e()])],1)],1)],1)}),_vm._v(" "),(_vm.$slots.loyalty)?_c('VfListItem',[_c('div',{staticClass:"vf-utility-nav__link"},[_vm._t("loyalty")],2)]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }