var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:['subnavigation', _vm.navigationsModify]},[_c('nav',{staticClass:"subnavigation__items container"},[_c('ul',{staticClass:"subnavigation__list"},[_vm._t("nav-item",function(){return _vm._l((_vm.items),function(item){return _c('li',{key:item.name,staticClass:"subnavigation-item"},[_c('ThemeLink',{staticClass:"subnavigation-item__link",class:{
                'subnavigation-item__link--active': item.url === _vm.activeItem,
              },attrs:{"to":item.url,"aria-current":item.url === _vm.activeItem ? 'page' : undefined}},[_vm._v("\n              "+_vm._s(item.name || item.value)+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"subnavigation-item__separator",attrs:{"aria-hidden":"true"}},[_vm._v("\n              "+_vm._s(_vm.separator)+"\n            ")])],1)})},null,{ separator: _vm.separator })],2)]),_vm._v(" "),_c('nav',{staticClass:"mobile-subnavigation",class:{
        'mobile-subnavigation--redesign-core': _vm.isCoreRedesignEnabled,
      }},[_c('div',{staticClass:"mobile-subnavigation-active",on:{"click":function($event){_vm.isMobileNavigationOpen = !_vm.isMobileNavigationOpen}}},[_vm._t("active-item",function(){return [_c('span',{staticClass:"mobile-subnavigation-active__name"},[_vm._v("\n            "+_vm._s(_vm.activeItemName)+"\n          ")])]}),_vm._v(" "),_c('VfIcon',{staticClass:"mobile-subnavigation-active__icon",class:{
            'vf-chevron--top': _vm.isMobileNavigationOpen,
          },attrs:{"icon":"chevron_down"}})],2),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.isMobileNavigationOpen)?_c('ul',{staticClass:"mobile-subnavigation__dropdown"},[_vm._t("nav-item-mobile",function(){return _vm._l((_vm.items),function(item){return _c('li',{key:("mobile-nav-" + (item.id) + "-" + (item.name)),class:[
                'mobile-subnavigation__item',
                _vm.isActiveClass(item.name)
                  ? 'mobile-subnavigation__item--active'
                  : '' ]},[_c('ThemeLink',{attrs:{"to":item.url,"aria-current":item.url === _vm.activeItem ? 'page' : undefined},on:{"click":function($event){return _vm.closeDropdownOnMobile()}}},[_vm._v("\n                "+_vm._s(item.name)+"\n              ")])],1)})},null,{ closeDropdownOnMobile: _vm.closeDropdownOnMobile })],2):_vm._e()])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }