//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'VfSubscriptionForm',
  mixins: [validationMixin],
  serverCacheKey: getCacheKeyFromProps,
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    privacyText: {
      type: String,
      default: '',
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    privacyError: {
      type: String,
      default: '',
    },
    validationMessages: {
      type: Object,
      default: () => {
        return {
          requiredError: '',
          emailError: '',
        };
      },
    },
    showPrivacy: {
      type: Boolean,
      default: false,
    },
    useIconForButton: {
      type: Boolean,
      default: false,
    },
    showInputLabelAbove: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    return {
      isCoreRedesignEnabled,
    };
  },
  data() {
    return {
      email: '',
      privacyAgree: false,
    };
  },
  computed: {
    isValidEmail() {
      return this.email && email(this.email);
    },
  },
  methods: {
    validateAndPropagateSubmit() {
      this.$v.$touch();
      this.$emit('clear-notifications');
      if (!this.$v.$invalid) {
        this.$emit('subscribe', this.email);
        this.email = '';
        this.privacyAgree = false;
        this.$v.$reset();
      }
    },
    handleVisible() {
      this.$emit('subscribe-shown');
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
      privacyAgree: {
        valid: (value) => {
          if (this.showPrivacy) {
            return value === true;
          } else {
            return true;
          }
        },
      },
    };
  },
});
