







































import {
  ref,
  computed,
  onMounted,
  watch,
  provide,
  unref,
  shallowRef,
  Ref,
  onBeforeUnmount,
} from '@vue/composition-api';
import debounce from '@vf/shared/src/utils/helpers/debounce';
import { TabItemComponent } from './types/TabItemComponent';

enum PaginationMode {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Dynamic = 'dynamic',
}

export default {
  name: 'VfTabs',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    caretColor: {
      type: String,
      default: 'gray',
    },
    caretHeight: {
      type: String,
      default: '4px',
    },
    hideCaret: {
      type: Boolean,
      default: false,
    },
    showSeparator: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: [Boolean, String],
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectedTabIndex = computed({
      get: () => {
        return props.value;
      },
      set: (value) => {
        emit('input', value);
      },
    });
    const tabsRef = shallowRef();
    const paginationPrev = shallowRef();
    const paginationNext = shallowRef();
    const tabsList: Ref<TabItemComponent[]> = ref([]);

    const caretWidth = ref(0);
    const caretLeft = ref(0);

    const caretStyles = computed(() => {
      if (props.hideCaret) {
        return {
          display: 'none',
        };
      }

      return {
        width: `${caretWidth.value}px`,
        height: props.caretHeight,
        backgroundColor: props.caretColor,
        transform: `translateX(${caretLeft.value}px)`,
        bottom: '0',
      };
    });

    const updateCaretPosition = (tab) => {
      const tabElement = unref(tab.tabRef);
      tabElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
      caretWidth.value = getValueOrDefault(tabElement, 'offsetWidth', 0);
      caretLeft.value =
        getValueOrDefault(tabElement, 'offsetLeft', 0) -
        (paginationModeComputed.value === PaginationMode.Enabled
          ? paginationPrevWidth.value
          : 0);
    };

    const getValueOrDefault = (target, key, defaultValue) =>
      target && target[key] ? target[key] : defaultValue;

    const updateTabsState = () => {
      tabsList.value.forEach((tab) => {
        tab.isActive = tab.id === selectedTabIndex.value;
        if (tab.isActive) {
          requestAnimationFrame(() => updateCaretPosition(tab));
        }
      });
    };

    watch(() => tabsList.value.length, updateTabsState);

    const selectTab = (tab) => {
      if (!tab) return;
      selectedTabIndex.value = tab.id;
    };

    const trackTab = (tab) => {
      tab.id = tabsList.value.push(tab) - 1;
    };

    const untrackTab = (tab) => {
      tabsList.value = tabsList.value.filter(
        (filteredTab) => filteredTab.id !== tab.id
      );
      tabsList.value.forEach((tab, index: number) => {
        tab.id = index;
      });
    };

    provide('VfTabsComponent', {
      selectTab,
      trackTab,
      untrackTab,
      tabsRef,
    });
    watch(() => selectedTabIndex.value, updateTabsState);

    const rightClosestHiddenElement = computed(() => {
      return tabsList.value.find(
        (tab, index, arr) => !tab.isVisible && arr[index - 1]?.isVisible
      );
    });
    const next = () => {
      if (!rightClosestHiddenElement.value) {
        return;
      }
      const tabElement = unref(rightClosestHiddenElement.value.tabRef);
      tabElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    };

    const leftClosestHiddenElement = computed(() => {
      return tabsList.value.find(
        (tab, index, arr) => !tab.isVisible && arr[index + 1]?.isVisible
      );
    });
    const prev = () => {
      if (!leftClosestHiddenElement.value) {
        return;
      }
      const tabElement = unref(leftClosestHiddenElement.value.tabRef);
      tabElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end',
      });
    };

    const tabsComputedStyles = computed(() => {
      const padding =
        paginationModeComputed.value === PaginationMode.Enabled
          ? {
              paddingLeft: `${paginationPrevWidth.value}px`,
              paddingRight: `${paginationNextWidth.value}px`,
            }
          : {};
      return {
        overflow: [PaginationMode.Enabled, PaginationMode.Dynamic].includes(
          paginationModeComputed.value
        )
          ? 'hidden'
          : 'visible',
        ...padding,
      };
    });

    const paginationModeComputed = computed(() => {
      if (typeof props.showPagination === 'boolean') {
        return props.showPagination
          ? PaginationMode.Enabled
          : PaginationMode.Disabled;
      }
      return props.showPagination;
    });

    const separatorComputedStyles = computed(() => {
      let prevWidth = 0;
      let nextWidth = 0;
      let width = '100%';

      switch (paginationModeComputed.value) {
        case PaginationMode.Enabled:
          prevWidth = paginationPrevWidth.value;
          nextWidth = paginationNextWidth.value;
          break;
        case PaginationMode.Disabled:
          prevWidth = 0;
          nextWidth = 0;
          width =
            getValueOrDefault(unref(tabsRef.value), 'clientWidth', 0) + 'px';
          break;
        case PaginationMode.Dynamic:
          prevWidth = leftClosestHiddenElement.value
            ? paginationPrevWidth.value
            : 0;
          nextWidth = rightClosestHiddenElement.value
            ? paginationNextWidth.value
            : 0;
          break;
      }

      return {
        width: `calc(${width} - ${prevWidth}px - ${nextWidth}px)`,
        marginLeft: `${prevWidth}px`,
        marginRight: `${nextWidth}px`,
        marginBottom: `calc(${props.caretHeight} - var(--vf-tabs-separator-height))`,
      };
    });

    const paginationPrevWidth = computed(() =>
      getValueOrDefault(unref(paginationPrev), 'clientWidth', 0)
    );

    const paginationNextWidth = computed(() =>
      getValueOrDefault(unref(paginationNext), 'clientWidth', 0)
    );

    const showPaginationComputed = computed(() => {
      let showPrev = false;
      let showNext = false;
      switch (paginationModeComputed.value) {
        case PaginationMode.Enabled:
          showPrev = true;
          showNext = true;
          break;
        case PaginationMode.Disabled:
          showPrev = false;
          showNext = false;
          break;
        case PaginationMode.Dynamic:
          showPrev = !!leftClosestHiddenElement.value;
          showNext = !!rightClosestHiddenElement.value;
          break;
      }
      return {
        showPrev,
        showNext,
      };
    });

    const debouncedUpdateTabsState = debounce(updateTabsState, 300);

    onMounted(() => {
      updateTabsState();
      window.addEventListener('resize', debouncedUpdateTabsState);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', debouncedUpdateTabsState);
    });

    return {
      tabsList,
      caretStyles,
      tabsRef,
      next,
      prev,
      tabsComputedStyles,
      paginationPrev,
      paginationNext,
      separatorComputedStyles,
      showPaginationComputed,
      paginationModeComputed,
    };
  },
};
