//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfBar',
  props: {
    title: {
      type: String,
      default: '',
    },
    back: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: () => '14px',
    },
    closeButtonClass: {
      type: String,
      default: '',
    },
    closeIcon: {
      type: String,
      default: 'cross',
    },
  },
});
