//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { AnalyticsDataDomLocation } from '../../theme/types';
import useRootInstance from '../../theme/shared/useRootInstance';
import { computed, defineComponent, inject } from '@vue/composition-api';

export default defineComponent({
  name: 'VfUtilityNavigation',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    linkTag: {
      type: String,
      default: 'a',
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const rolesSSR = ['link', 'cart', 'search', 'account'];
    const isSSR = (item) => rolesSSR.includes(item.role);
    const isChat = (item) => item.role.toLowerCase() === 'chat';
    const isSignOut = (item) => item.role.toLowerCase() === 'logout';
    const utilityIconSize = root.$themeConfig?.header?.utilityIcon;
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const listItemAdditionalClass =
      root.$themeConfig?.utilityNavigation?.listItemAdditionalClass || '';

    const navItems = computed(() =>
      props.items.filter(
        (item) =>
          item.visible &&
          root.$stateManagementChecker(item.states) &&
          root.$segmentsChecker(item.segments)
      )
    );

    const getComponentName = (item) => {
      let type = 'theme-link';
      let link = item.link;
      let role = '';
      if (isChat(item)) {
        type = 'vf-chat-link';
      }
      if (isSignOut(item)) {
        type = 'theme-button';
        link = null;
      }
      if (item.openInNewModal) {
        if (item.teaserTargetType === 'CMChannel') {
          role = 'button';
          type = 'theme-link';
        } else {
          type = 'theme-button';
          link = null;
        }
      }
      return {
        ...(role && { role }),
        type,
        link,
      };
    };

    const handleNavLinkClick = (e, data) => {
      if (data.isModal) {
        e.preventDefault();
      }
      emit('utility-nav-menu-link-clicked', {
        link: data.link,
        title: data.title,
        role: data.role,
        isModal: data.isModal,
      });
    };

    return {
      isSSR,
      AnalyticsDataDomLocation,
      navItems,
      handleNavLinkClick,
      listItemAdditionalClass,
      utilityIconSize,
      getComponentName,
      isCoreRedesignEnabled,
    };
  },
});
