
































































import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type {
  CTAButtons,
  Icons,
  RegularButtonSize,
  RegularButtonStyle,
} from '@vf/api-contract';

export default defineComponent({
  name: 'VfImageTileActionButtons',
  props: {
    link: {
      type: String as PropType<string>,
      default: null,
    },
    buttonText: {
      type: String as PropType<string>,
      default: '',
    },
    buttonStyle: {
      type: String as PropType<RegularButtonStyle>,
      default: 'tertiary123',
    },
    buttonSize: {
      type: String as PropType<RegularButtonSize>,
      default: 'medium',
    },
    buttonIcon: {
      type: String as PropType<Icons>,
      default: '',
    },
    contentPosition: {
      type: Object,
      default: () => ({
        small: 'center',
        medium: 'center',
        large: 'center',
      }),
    },
    showOverlay: {
      type: Object,
      default: () => ({
        small: true,
        medium: true,
        large: true,
      }),
    },
    dataNavigation: {
      type: String as PropType<string>,
      default: null,
    },
    dataDomLocation: {
      type: String as PropType<string>,
      default: null,
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    openInNewModal: {
      type: Boolean,
      default: false,
    },
    delayButtons: {
      type: Boolean,
      default: false,
    },
    ctaButtons: {
      type: Array as PropType<CTAButtons[]>,
      default: () => [
        {
          buttonText: '',
          buttonStyle: '',
          buttonSize: '',
          buttonIcon: '',
          buttonIconPosition: '',
          buttonLink: '',
          buttonUnderline: '',
          buttonTargetComponent: '',
        },
      ],
    },
    buttonTargetComponent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isHidden: true,
    };
  },
  created() {
    if (!this.delayButtons) this.isHidden = false;
  },
  mounted() {
    setTimeout(() => {
      this.isHidden = false;
    }, 100);
  },
  methods: {
    emitCustomModal(target: string) {
      if (this.openInNewModal && target === 'CMVideo') {
        this.$emit('custom-modal-styles');
      }
    },
  },
});
