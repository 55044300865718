//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, inject } from '@vue/composition-api';

export default defineComponent({
  name: 'VfMobileMenu',
  provide() {
    const megaMenu = {};
    Object.defineProperty(megaMenu, 'active', {
      get: () => this.active,
    });
    Object.defineProperty(megaMenu, 'changeActive', {
      value: this.changeActive,
    });
    return { megaMenu };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    /**
     * @deprecated will be removed in 1.0.0
     */
    asideTitle: {
      type: String,
      default: '',
    },
  },
  setup() {
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      isCoreRedesignEnabled,
    };
  },
  data() {
    return {
      active: '',
    };
  },
  watch: {
    '$viewport.isSmall': {
      handler() {
        this.active = '';
      },
      immediate: true,
    },
  },
  methods: {
    changeActive(payload) {
      if (!this.$viewport.isSmall) return;
      this.active = payload;
      this.$emit('change', payload);
    },
  },
});
