


























import {
  computed,
  defineComponent,
  inject,
  shallowRef,
  onMounted,
  onBeforeUnmount,
  onBeforeMount,
  ref,
  Ref,
} from '@vue/composition-api';
import { TabItemComponent } from './types/TabItemComponent';

export default defineComponent({
  name: 'VfTabItem',
  props: {
    label: { type: String, default: '' },
    selected: Boolean,
    disabled: Boolean,
    tag: { type: String, default: 'div' },
  },
  setup(props) {
    const { selectTab, trackTab, untrackTab, tabsRef } = inject(
      'VfTabsComponent'
    );
    const tabIndexComputed = computed(() => (props.disabled ? -1 : 0));
    const tabRef = shallowRef();

    const tabComponentRef: Ref<TabItemComponent> = ref({
      id: null,
      tabRef,
      isVisible: false,
      isActive: false,
    });

    const onTabClick = () => {
      selectTab(tabComponentRef.value);
    };

    const observer = ref(null);

    onMounted(() => {
      observer.value = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            tabComponentRef.value.isVisible = entry.isIntersecting;
          });
        },
        { root: tabsRef.value, threshold: 0.9 }
      );
      observer.value.observe(tabRef.value);
    });

    const labelComputedStyles = computed(() => {
      return {
        fontWeight: tabComponentRef.value.isActive
          ? 'var(--vf-tab-item-hovered-font-weight)'
          : 'var(--vf-tab-item-font-weight)',
      };
    });
    onBeforeMount(() => {
      trackTab(tabComponentRef.value);
    });

    onBeforeUnmount(() => {
      untrackTab(tabComponentRef.value);
    });

    return {
      tabIndexComputed,
      onTabClick,
      tabRef,
      labelComputedStyles,
      tabComponentRef,
    };
  },
});
