






















































import {
  defineComponent,
  computed,
  watch,
  ComputedRef,
  PropType,
} from '@vue/composition-api';
import { focusTrap } from '@vf/shared/src/utils/directives';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { isClient } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfSidebar',
  directives: { focusTrap },
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    subtitle: {
      type: String as PropType<string>,
      default: '',
    },
    headingLevel: {
      type: Number as PropType<number>,
      default: 3,
    },
    button: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    visible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    overlay: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    titleModifier: {
      type: String as PropType<string>,
      default: '',
    },
    subtitleModifier: {
      type: String as PropType<string>,
      default: '',
    },
    position: {
      type: String as PropType<string>,
      default: 'left',
    },
  },
  setup(props, { root, refs, emit }) {
    const isVisible: ComputedRef<boolean> = computed(() => props.visible);
    const visibleOverlay: ComputedRef<boolean> = computed(() => {
      return props.visible && props.overlay;
    });
    const transitionName: ComputedRef<string> = computed(() => {
      return `vf-sidebar-${props.position}-transition`;
    });

    const close = () => {
      emit('close');
    };

    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        close();
      }
    };

    watch(
      isVisible,
      (value) => {
        if (!isClient) return;
        if (value) {
          root.$nextTick(() => {
            disableBodyScroll(refs.content as HTMLElement);
          });
          document.addEventListener('keydown', keydownHandler);
        } else {
          clearAllBodyScrollLocks();
          document.removeEventListener('keydown', keydownHandler);
        }
      },
      { immediate: true }
    );

    return {
      close,
      visibleOverlay,
      transitionName,
      isClient,
    };
  },
});
