









































































































































import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import type {
  CategoryTeasersStyles,
  Image,
  CTAButtons,
  Icons,
  RegularButtonSize,
  RegularButtonStyle,
} from '@vf/api-contract';
import { generateDimensionsSettings } from '@vf/shared/src/utils/helpers/generateDimensionsSettings';
import VfImageTileActionButtons from './Molecule.ImageTileActionButtons.vue';
import { ImageSizes } from '@vf/theme/helpers/extractImageSizes';

const defaultOverlaySetting = () => ({
  hOffset: null,
  vOffset: null,
  width: '',
  color: '',
  opacity: 0,
  showOnHover: false,
});

export default defineComponent({
  name: 'VfImageTile',
  components: {
    VfImageTileActionButtons,
  },
  props: {
    image: {
      type: [Object, String] as PropType<Image | string>,
      default: '',
    },
    imageWidth: {
      type: [String, Number, Object] as PropType<string | number | ImageSizes>,
      default: null,
    },
    imageHeight: {
      type: [String, Number, Object] as PropType<string | number | ImageSizes>,
      default: null,
    },
    title: {
      type: String as PropType<string>,
      default: '',
    },
    text: {
      type: String as PropType<string>,
      default: '',
    },
    subtitle: {
      type: String as PropType<string>,
      default: '',
    },
    useRichText: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    imageLink: {
      type: String as PropType<string>,
      default: null,
    },
    link: {
      type: String as PropType<string>,
      default: null,
    },
    buttonText: {
      type: String as PropType<string>,
      default: '',
    },
    buttonStyle: {
      type: String as PropType<RegularButtonStyle>,
      default: 'tertiary',
    },
    buttonSize: {
      type: String as PropType<RegularButtonSize>,
      default: 'medium',
    },
    buttonIcon: {
      type: String as PropType<Icons>,
      default: '',
    },
    styles: {
      type: Object as PropType<CategoryTeasersStyles>,
      default: () => ({
        titleColor: '',
        titleBackgroundColor: '',
        titleFontSize: '',
        titleFontWeight: '',
        titleFontFamily: '',
        headingLevel: '',
        titleHeadingStyle: '',
        titleClassModifiers: '',
        textColor: '',
        textBackgroundColor: '',
        textClassModifiers: '',
        subtitleColor: '',
        subtitleFontSize: '',
        subtitleFontWeight: '',
        subtitleFontFamily: '',
        subtitleBackgroundColor: '',
        subtitleClassModifiers: '',
        subtitleHeadingStyle: '',
      }),
    },
    contentPosition: {
      type: Object,
      default: () => generateDimensionsSettings(() => 'middle_center'),
    },
    textAlign: {
      type: Object,
      default: () => generateDimensionsSettings(() => 'center'),
    },
    showOverlay: {
      type: Object,
      default: () => generateDimensionsSettings(() => true),
    },
    transparentOverlay: {
      type: Object,
      default: () => generateDimensionsSettings(() => false),
    },
    alwaysShowOverlay: {
      type: Object,
      default: () => generateDimensionsSettings(() => false),
    },
    showButtonsBelowImage: {
      type: Boolean,
      default: false,
    },
    overlaySettingsSmall: {
      type: Object,
      default: () => defaultOverlaySetting(),
    },
    overlaySettingsMedium: {
      type: Object,
      default: () => defaultOverlaySetting(),
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    openInNewModal: {
      type: Boolean,
      default: false,
    },
    overlaySettingsLarge: {
      type: Object,
      default: () => defaultOverlaySetting(),
    },
    dataNavigation: {
      type: String as PropType<string>,
      default: null,
    },
    dataDomLocation: {
      type: String as PropType<string>,
      default: null,
    },
    delayButtons: {
      type: Boolean,
      default: false,
    },
    ctaButtons: {
      type: Array as PropType<CTAButtons[]>,
      default: () => [
        {
          buttonText: '',
          buttonStyle: '',
          buttonSize: '',
          buttonIcon: '',
          buttonIconPosition: '',
          buttonLink: '',
          buttonUnderline: '',
          buttonTargetComponent: '',
        },
      ],
    },
    buttonTargetComponent: {
      type: String,
      default: '',
    },
  },
  setup(props, { root, emit }) {
    const hasOverlayVisible = computed(() => {
      return props.showOverlay[root.$viewport?.size || 'small'];
    });

    const articleImage = computed(() =>
      props.image === 'string' ? { large: props.image } : props.image
    );
    const articleLink = computed(() => {
      return props.link && props.ctaButtons.length > 0
        ? props.imageLink
        : props.imageLink || props.link;
    });
    const customTitleStyles = computed(() => ({
      titleColor: props.styles.titleColor,
      titleFontSize: props.styles.titleFontSize,
      titleFontFamily: props.styles.titleFontFamily,
      titleFontWeight: props.styles.titleFontWeight,
      titleBackgroundColor: props.styles.titleBackgroundColor,
      subtitleColor: props.styles.subtitleColor,
      subtitleFontSize: props.styles.subtitleFontSize,
      subtitleFontWeight: props.styles.subtitleFontWeight,
      subtitleFontFamily: props.styles.subtitleFontFamily,
      subtitleBackgroundColor: props.styles.subtitleBackgroundColor,
      subtitleClassModifiers: props.styles.subtitleClassModifiers,
    }));
    const customTextStyles = computed(() => {
      return generateDimensionsSettings((dimension) => {
        return textOverlayColorSettings.value[dimension.toLowerCase()];
      }, '--text-overlay-color');
    });
    const contentPositionSettingsStyles = computed(() => ({
      ...generateDimensionsSettings((dimension) => {
        const offset = props[`overlaySettings${dimension}`].hOffset;
        return offset && `${offset}px`;
      }, '--vf-image-tile-content-position-left'),
      ...generateDimensionsSettings((dimension) => {
        const offset = props[`overlaySettings${dimension}`].vOffset;
        return offset && `${offset}px`;
      }, `--vf-image-tile-content-position-top`),
    }));
    const contentWidthStyles = computed(() => {
      return generateDimensionsSettings((dimension) => {
        return props[`overlaySettings${dimension}`].width || undefined;
      }, `--vf-image-tile-content-width`);
    });

    const overlayColorSettings = computed(() => {
      return generateDimensionsSettings((dimension) => {
        return props.transparentOverlay[dimension.toLowerCase()]
          ? 'transparent'
          : props[`overlaySettings${dimension}`].color;
      });
    });

    const textOverlayColorSettings = computed(() => {
      return generateDimensionsSettings((dimension) => {
        const overlayColor =
          overlayColorSettings.value[dimension.toLowerCase()];
        const { textBackgroundColor, textColor } = props.styles;
        const isOverlayEnabled =
          !overlayColor || overlayColor !== 'transparent';
        const isOnlyTextColorEnabled = !!textColor && !textBackgroundColor;
        if (isOverlayEnabled || isOnlyTextColorEnabled) {
          return 'transparent';
        }
        return textBackgroundColor !== 'transparent' ? textBackgroundColor : '';
      });
    });

    const overlayOpacitySettings = computed(() =>
      generateDimensionsSettings((dimension) => {
        return props[`overlaySettings${dimension}`].opacity ?? undefined;
      })
    );

    const contentOverlayOpacity = computed(() => {
      const DEFAULT_OPACITY_PERCENTAGE = 40;

      const overlayOpacityInt = overlayOpacitySettings.value[
        root.$viewport?.size || 'small'
      ] as number;
      return (overlayOpacityInt ?? DEFAULT_OPACITY_PERCENTAGE) * 0.01;
    });

    const showContentOnHoverSettings = computed(() =>
      generateDimensionsSettings((dimension) => {
        return props[`overlaySettings${dimension}`].showOnHover || undefined;
      })
    );

    const showContentOnHover = computed(() => {
      return showContentOnHoverSettings.value[root.$viewport?.size || 'small'];
    });

    const backgroundStyles = computed(() => {
      return generateDimensionsSettings((dimension) => {
        return overlayColorSettings.value[dimension.toLowerCase()] || undefined;
      }, '--content-overlay-background');
    });

    const linkTargetAttribute = computed(() =>
      props.openInNewTab ? '_blank' : ''
    );

    const onClickHandler = (e: MouseEvent, link?: string) => {
      emit('click-anchor', { event: e, link });
    };

    const alt = computed(() =>
      typeof props.image === 'object' ? props.image.alt : props.image
    );

    return {
      hasOverlayVisible,
      articleImage,
      articleLink,
      customTitleStyles,
      customTextStyles,
      contentPositionSettingsStyles,
      contentWidthStyles,
      linkTargetAttribute,
      onClickHandler,
      overlayColorSettings,
      overlayOpacitySettings,
      contentOverlayOpacity,
      textOverlayColorSettings,
      showContentOnHoverSettings,
      showContentOnHover,
      backgroundStyles,
      alt,
    };
  },

  data() {
    return {
      imgLoaded: false,
      uniqueId: null,
    };
  },

  mounted() {
    this.uniqueId = `image-tile-${this._uid}`;
  },
});
