//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfFavoritesTooltip',
  props: {
    isFavoritesTooltipOpen: {
      type: Boolean,
      default: false,
    },
    favoritesCount: {
      type: Number,
      default: 0,
    },
    isCustomerLoggedIn: {
      type: Boolean,
      default: false,
    },
    favoritesListUrl: {
      type: String,
      default: '',
    },
    createAccountUrl: {
      type: String,
      default: '',
    },
    translations: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const unauthorisedMessage = props.translations.notificationUnauthorised
      .replace(
        /{{login\|([A-zÀ-ú ]+)}}/gi,
        `<a href="${props.favoritesListUrl}">$1</a>`
      )
      .replace(
        /{{register\|([A-zÀ-ú ]+)}}/gi,
        `<a href="${props.createAccountUrl}">$1</a>`
      );

    return {
      unauthorisedMessage,
    };
  },
});
