



































































































































import { getIdFromUrl as extractYoutubeVideoID } from 'vue-youtube';
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
} from '@vue/composition-api';
import { VideoObject } from '@vf/api-contract/src';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import {
  addFullScreenEventListener,
  removeFullScreenEventListener,
  getFullscreenElement,
} from '@vf/shared/src/utils/helpers/fullscreen';

interface DOMVideoEvent extends Event {
  readonly target: HTMLMediaElement;
}

export default defineComponent({
  name: 'VfVideo',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    video: {
      type: Object as PropType<{
        small: VideoObject;
        medium: VideoObject;
        large: VideoObject;
      }>,
      default: () => ({
        small: {
          sources: [],
          seo: {
            title: '',
            date: '',
            description: '',
          },
          tracks: [],
          poster: '',
          options: {
            preload: 'metadata',
            autoplay: false,
            hideControls: false,
            muted: true,
            loop: false,
            showPlayButton: false,
            showSubtitles: false,
          },
        },
      }),
    },
    /** Additional div layer for youtube videos to add touch behaviour over video iframe */
    useYtTouchLayer: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Determines if overlay is visible for current viewport */
    showOverlay: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    /** Determines if overlay is transparent */
    transparentOverlay: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    /** Determines if overlay should be always visible */
    alwaysShowOverlay: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    /** Determines if just the buttons should be displayed below banner image */
    showButtonsBelowImage: {
      type: Boolean,
      default: false,
    },
    /** Overlay colors **/
    overlayColorSettings: {
      type: Object,
      default: () => ({
        small: '',
        medium: '',
        large: '',
      }),
    },
    /** Overlay opacity **/
    overlayOpacitySettings: {
      type: Object,
      default: () => ({
        small: 0,
        medium: 0,
        large: 0,
      }),
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    /** Determines overlay position */
    overlayPosition: {
      type: Object,
      default: () => ({
        small: 'middle_center',
        medium: 'middle_center',
        large: 'middle_center',
      }),
    },
    /** Set a fixed 16:9 Aspect Ratio (only for youtube player) */
    ytAspect16To9: { type: Boolean, default: true },
    customVideo: {
      type: Boolean,
    },
    /** Link to be applied to video */
    imageLink: {
      type: String as PropType<string>,
      default: '',
    },
    /** Load video source CSR only */
    lazyLoad: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, slots, emit }) {
    const showPlayButtonRef = ref(true);
    const mainVideoRef = ref<HTMLMediaElement>(null);
    const isVideoPlaying = ref(false);
    const isEventsTracked = ref(true);

    const videoToDisplay = computed(() => {
      switch (root.$viewport?.size) {
        case 'large':
          return props.video.large || props.video.medium || props.video.small;
        case 'medium':
          return props.video.medium || props.video.small;
        default:
          return props.video.small;
      }
    });

    const youtube = computed(() => {
      const link = videoToDisplay.value?.sources?.[0]?.src || '';
      const youtubeId = extractYoutubeVideoID(link);
      if (youtubeId) {
        return {
          id: `yt-video-${youtubeId}`,
          videoId: youtubeId,
          url: link,
        };
      }
      return false;
    });

    const playVideo = async () => {
      if (!youtube.value) {
        await mainVideoRef.value?.play();
        showPlayButtonRef.value = false;
      }
    };

    const handleYTVideoPlayStatus = (isPlaying: boolean) => {
      isVideoPlaying.value = isPlaying;
    };

    const showPlayButton = computed(
      () =>
        videoToDisplay.value?.options?.showPlayButton &&
        showPlayButtonRef.value &&
        (!hasOverlay.value || !isOverlayVisible.value) &&
        !youtube.value
    );

    watch(
      () => root.$viewport?.size,
      () => {
        nextTick(() => {
          mainVideoRef.value?.load();
          if (isVideoPlaying.value) playVideo(); // Play new loaded video
        });
      }
    );

    const playingHandler = () => {
      showPlayButtonRef.value = false;
      isVideoPlaying.value = true;
    };

    const playHandler = (e: DOMVideoEvent) => {
      if (props.customVideo) {
        emit('video-event', {
          eventCategory: 'Customs',
          eventAction: 'Add Your Touch',
          name: 'Start Playing',
          nonInteractive: false,
        });
      }
      if (isEventsTracked.value && !isVideoPlaying.value) {
        emit('video-event', {
          eventAction:
            Math.round(e.target.currentTime) === 0 ? 'Start' : 'Play',
          videoDuration: Math.round(e.target.duration),
          videoCurrentTime: Math.round(e.target.currentTime),
          name: videoToDisplay.value.name,
        });
      }
      showPlayButtonRef.value = false;
      isVideoPlaying.value = true;
    };

    const pauseHandler = (e: DOMVideoEvent) => {
      if (!e.target.seeking) {
        isVideoPlaying.value = false;
        showPlayButtonRef.value = true;
        if (e.target.duration !== e.target.currentTime) {
          if (!videoToDisplay.value?.options?.hideControls)
            isEventsTracked.value = true;
          emit('video-event', {
            eventAction: 'Pause',
            videoDuration: Math.round(e.target.duration),
            videoCurrentTime: Math.round(e.target.currentTime),
            name: videoToDisplay.value.name,
          });
        }
      }
    };

    const endHandler = (e: DOMVideoEvent) => {
      if (isEventsTracked.value) {
        emit('video-event', {
          eventAction: 'Complete',
          videoDuration: Math.round(e.target.duration),
          videoCurrentTime: Math.round(e.target.currentTime),
          name: videoToDisplay.value.name,
        });
      }
      //manual loop to emit datalayer event
      if (videoToDisplay.value?.options?.loop) {
        isEventsTracked.value = false;
        playVideo();
      } else {
        isEventsTracked.value = true;
      }
    };

    const isFullScreen = () => !!getFullscreenElement();

    const fullscreenchangeHandler = (e: DOMVideoEvent) => {
      if (isEventsTracked.value) {
        emit('video-event', {
          eventAction: `Full Screen ${isFullScreen() ? 'On' : 'Off'}`,
          videoDuration: Math.round(e.target.duration),
          videoCurrentTime: Math.round(e.target.currentTime),
          name: videoToDisplay.value.name,
        });
      }
    };

    onMounted(() => {
      if (mainVideoRef.value) {
        mainVideoRef.value.addEventListener('play', playHandler);
        mainVideoRef.value.addEventListener('playing', playingHandler);
        mainVideoRef.value.addEventListener('pause', pauseHandler);
        mainVideoRef.value.addEventListener('ended', endHandler);
        addFullScreenEventListener(fullscreenchangeHandler, mainVideoRef.value);
      }
      //manual autoplay to emit datalayer event
      if (videoToDisplay.value?.options?.autoplay) {
        isEventsTracked.value = false;
        playVideo();
      }
    });

    onBeforeUnmount(() => {
      if (mainVideoRef.value) {
        mainVideoRef.value.removeEventListener('play', playHandler);
        mainVideoRef.value.removeEventListener('playing', playingHandler);
        mainVideoRef.value.removeEventListener('pause', pauseHandler);
        mainVideoRef.value.removeEventListener('ended', endHandler);
        removeFullScreenEventListener(
          fullscreenchangeHandler,
          mainVideoRef.value
        );
      }
    });

    const hasOverlay = computed(() => {
      return !!slots.default;
    });
    const isOverlayVisible = computed(() => {
      return props.showOverlay[root.$viewport?.size || 'small'];
    });

    const videoOverlayOpacity = computed(() => {
      const DEFAULT_OPACITY_PERCENTAGE = 40;

      const overlayOpacityInt =
        props.overlayOpacitySettings[root.$viewport?.size || 'small'];
      return (overlayOpacityInt ?? DEFAULT_OPACITY_PERCENTAGE) * 0.01;
    });

    const videoOverlayBackground = computed(() => {
      const color = props.overlayColorSettings[root.$viewport?.size || 'small'];

      // Convert Hex color to RGB
      if (color && color[0] === '#') {
        const result = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
        if (result && result.length === 4) {
          return (
            `${parseInt(result[1], 16)}, ` +
            `${parseInt(result[2], 16)}, ` +
            `${parseInt(result[3], 16)}`
          );
        }
      }
      return '0, 0, 0';
    });

    const linkTargetAttribute = computed(() =>
      props.openInNewTab ? '_blank' : ''
    );

    return {
      showPlayButtonRef,
      mainVideoRef,
      videoToDisplay,
      youtube,
      playVideo,
      handleYTVideoPlayStatus,
      showPlayButton,
      hasOverlay,
      isOverlayVisible,
      endHandler,
      playHandler,
      isVideoPlaying,
      pauseHandler,
      fullscreenchangeHandler,
      isEventsTracked,
      videoOverlayOpacity,
      videoOverlayBackground,
      linkTargetAttribute,
    };
  },
});
